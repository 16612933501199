<template>
    <div
        class="container"
        :loading="loading"
    >
        <van-form @submit="onSubmit">
            <div
                class="item tab"
                v-for="(item,index) in InductionQuList"
                :key="item.id"
            >
                <p class="questionTitle">{{index+1}}、{{ item.question }}</p>
                <van-field
                    v-model="item.answer"
                    rows="1"
                    autosize
                    type="textarea"
                    placeholder="请输入"
                />
            </div>
            <div
                class="btn"
                style="margin:0 16px;"
            >
                <van-button
                    round
                    block
                    type="info"
                    native-type="submit"
                >提交</van-button>
            </div>
        </van-form>
    </div>
</template>

<script>
import { GetHrQuestions, SubmitQuAnswer } from "@/api/interview";

export default {
    name: "EntryTest",
    data() {
        return {
            loading: false,
            InductionQuList: [],
        };
    },
    created() {
        this.getInduList();
    },
    methods: {
        //小测试列表
        async getInduList() {
            let res = await GetHrQuestions({
                openId: this.$store.state.info.openId,
            });
            if (res.errcode == 200) {
                this.InductionQuList = res.questions;
            }
        },
        async onSubmit() {
            console.log(this.InductionQuList, "InductionQuList");
            let is_answer = this.InductionQuList.every((item) => item.answer);
            if (!is_answer)
                return this.$notify({
                    type: "warning",
                    message: "小问题还没有答完哦~",
                });
            let answers = this.InductionQuList.map((item) => {
                console.log(item, "item11");
                return {
                    quesId: item.id,
                    questions: item.question,
                    answer: item.answer,
                };
            });
            let res = await SubmitQuAnswer({
                openId: this.$store.state.info.openId,
                answer: JSON.stringify(answers),
            });
            if (res.errcode == 200) {
                this.$notify({ type: "success", message: "提交成功" });
            } else {
                this.$notify({ type: "warning", message: res.message });
            }
        },
    },
};
</script>

<style scoped lang="scss">
.container {
    padding-bottom: 80px;
    padding-top: 5px;
    .tab {
        margin-top: 5px;
        .questionTitle {
            padding: 0 16px;
            margin-bottom: 0;
            font-size: 14px;
            color: #646566;
        }
    }
    .van-cell {
        border: 1px dashed #ccc;
        margin: 10px;
        width: 90%;
    }
    .btn {
        width: 90%;
        position: fixed;
        bottom: 10px;
    }
}
</style>
